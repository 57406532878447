import React from 'react'
import './home.css'

function Home() {
    return (
        <div className="general">
            <div className="CathyPottery">
                Chialing Hsieh
            </div>
            <div className="lines">
                <div className="line1"></div>
                <div className="line2"></div>
            </div>
        </div>
    )
}

export default Home
