import React from 'react'
import './contact.css'
import { useLocation } from 'react-router-dom'

function Contact() {
    let atHome = (useLocation().pathname === "/")

    if (atHome === false) {
        return (
            <div className="background">
                <div className="contact-container">
                    <div>
                        <div className="xline1"></div>
                        <div className="xline2"></div>
                    </div>
                    <div className="flex">
                        <div className="specialColor">
                            Chialing Hsieh
                        </div>
                        <div className="normal-item">
                            chialinghsiehstudio@gmail.com
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (<div />)
    }
}

export default Contact
