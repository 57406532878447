import React from 'react'
import './about.css'
import cover from './img/potter.png'

function About() {
    return (
        <div className="general">
            <div className="background">
                <div className="works-title">
                    The Artist
                </div>
                <div className="altx">
                    <div className="altxline1"></div>
                    <div className="altxline2"></div>
                </div>
                <div className="about-cover">
                    <img src={cover} alt="about-photo" className="about-photo" />
                </div>
                <div className="about-info">
                    Chialing Hsieh currently resides in Roanoke, VA. Many of her creative works are inspired 
                    from travel and experiences through life. Her work focuses on experimenting with glazes, 
                    leveraging the effect achieved with high heat in glazed layers that melt and blend to 
                    create a myriad of new colors.
                </div>
            </div>
        </div>
    )
}

export default About
