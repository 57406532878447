import React from 'react'
import './works.css'


const vasesContext = require.context('./img/pottery/vases', false, /\.(png|jpg|jpeg|heic|HEIC|JPG|PNG)$/);
const vases = vasesContext.keys().map(vasesContext);


function Vases() {

    const leftTitle = (
        <div className="left-title">
            <div>
                <a href="/works/decor" className="whiteLink">| Decor</a>
            </div>
            <br />
            <div>
                <a href="/works/plates" className="whiteLink">| Plates</a>
            </div>
            <br />
            <div>
                <a href="/works/bowls" className="whiteLink">| Bowls</a>
            </div>
            <br />
            <div>
                <a href="/works/cups" className="whiteLink">| Cups</a>
            </div>
            <br />
            <div>
                <a href="/works/vases" className="yellowLink">| Vases</a>
            </div>
            <br />
            <div>
                <a href="/works/teapots" className="whiteLink">| Teapots</a>
            </div>
            <br />
            {/* <div>
                <a href="/works/videos" className="whiteLink">| Videos</a>
            </div>
            <br /> */}
            <div>
                <a href="/works/bts" className="whiteLink">| BTS</a>
            </div>
        </div>
    );

    return (
        <div className="general">
            <div className="background">
                <div className="works-title">
                    Works
                </div>
                <div className="altx">
                    <div className="altxline1"></div>
                    <div className="altxline2"></div>
                </div>
                <div className="categories">
                    <div className="category-boxing" id="vasesSection">
                        {leftTitle}
                        <div className="category-flex">
                            <div className="category-title">
                                Vases
                                <br />
                                <div className="underline">______________</div>
                            </div>
                            <div className="picture-grid">
                                {vases.map((picture, index) => (
                                    <img
                                    key={index}
                                    src={picture}
                                    loading="lazy"
                                    alt={`Picture ${index + 1}`}
                                    className="grid-picture"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vases
